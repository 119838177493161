body {
}

.nav-item:hover > .nav-link .menu-title {
  color: darkgoldenrod;
}

.auth-page .auth-side-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../public/assets/images/First.svg");
  border-right: 1px solid #e9ecef;
  background-size: 300px;
}

/* rgb(255 203 0 / 20%) */
